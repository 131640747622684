<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        pill
        @click="createInterpratorService"
        v-if="hasPermission('create_interprator_service')"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div>

    <b-card>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="interpratorServices"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('read_interprator_service')"
        :per-page="0"
      >
        <template #custom-foot>
          <b-tr variant="light">
            <b-td :colspan="fields.length">
              <span class="font-weight-bolder">
                Total Rows: {{ totalItems }}
              </span>
            </b-td>
          </b-tr>
        </template>
        <template #cell(serial)="row">
        {{ (currentPage - 1) * perPage + row.index + 1 }}
      </template>
      <template #cell(name)="row">
        {{ truncate(row.item.name, 20) }} 
      </template>
      <template #cell(location)="row">
        {{ truncate(row.item.location, 20) }} 
      </template>
        <template #cell(interprator_company)="row">
          {{ truncate(row.item.interprator_company_name, 20) }}
        </template>

        <template #cell(created_at)="row">
          {{ row.item.created_at }}
        </template>
        <template #cell(updated_at)="row">
          {{ row.item.updated_at }}
        </template>
        <template #cell(created_by_data)="row">
          {{
            row.item.created_by
          }}
        </template>
        <template #cell(updated_by_data)="row">
          {{
            row.item.updated_by
          }}
        </template>
        <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="editInterpratorService(row.item)"
            v-if="hasPermission('update_interprator_service')"
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="removeInterpratorService(row.item)"
            v-if="hasPermission('delete_interprator_service')"
          >
            Delete
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
      <!-- <span class="mt-1 bg1 float-right" >Total Rows: {{ rows }}</span> -->
    </b-card>
    <interprator-service-create-modal
      @modalClosed="onModalClosed"
      :key="`create-${interpratorServiceCreateModalCount}`"
    />
    <interprator-service-edit-modal
      :interpratorService="interpratorService"
      @modalClosed="onModalClosed"
      :key="`edit-${interpratorServiceEditModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import InterpratorServiceCreateModal from "@/components/admin/interpratorService/InterpratorServiceCreateModal.vue";
import InterpratorServiceEditModal from "@/components/admin/interpratorService/InterpratorServiceEditModal.vue";

export default {
  components: {
    InterpratorServiceCreateModal,
    InterpratorServiceEditModal,
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "name", label: "Name" },
        { key: "location", label: "Location" },
        { key: "shift", label: "Shift" },
        { key: "amount", label: "Amount" },
        { key: "interprator_company", label: "Company" },
        { key: "created_at", label: "Created At" },
        { key: "created_by_data", label: "Created By" },
        { key: "updated_at", label: "Created At" },
        { key: "updated_by_data", label: "Updated By" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      interpratorServices: [],
      interpratorService: null,
      InterpratorServiceCreateModal: 0,
      InterpratorServiceEditModal: 0,
      interpratorServiceCreateModalCount: 0,
      interpratorServiceEditModalCount: 0,
      nameFilter: "",
    };
  },
  mixins: [util],
  async mounted() {
    await this.fetchPaginatedData();
  },
  methods: {
    ...mapActions({
      getInterpratorService: "appData/getInterpratorService",
      deleteInterpratorService: "appData/deleteInterpratorService",
    }),
    async filter() {
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async fetchPaginatedData() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
        };
        if (this.nameFilter) {
          tempParams["name"] = this.nameFilter;
        }
        const res = await this.getInterpratorService(tempParams);
        this.interpratorServices = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    createInterpratorService() {
      this.interpratorServiceCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("interprator-service-create-modal");
      });
    },
    editInterpratorService(interpratorService) {
      this.interpratorService = interpratorService;
      this.interpratorServiceEditModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("interprator-service-edit-modal");
      });
    },
    async removeInterpratorService(interpratorService) {
      this.interpratorService = interpratorService;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteInterpratorService({
              pk: this.interpratorService.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: "InterpratorService deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            this.show = false;
            this.displayError(error);
          }
        } else {
          this.show = false;
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
    rows() {
      return this.interpratorServices.length;
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>
